import '../App.css';
import React from 'react';
import Header from '../components/Header';
import LeftSideBar from '../components/LeftSideBar';
import RightBody from '../components/RightBody';
import {withRouter} from './util';


class TnoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount() {
    
  }

  render() {
    return (
      <div className='body'>
        <Header></Header>
        <div style={{height: "80px"}}></div>{/* place holder for header */}

        <div style={{maxWidth: "1920px", minWidth: "755px", margin: "0 auto"}}>
          <LeftSideBar></LeftSideBar>
          <RightBody>
          The New Order(TNO) is a game DLC for Paradox's military simulation game Heart of Iron IV. 
          <br/><br/>
          Its story is based on a purely hypothetical assumption: if the Axis won the World War II
          
          <br/><br/>
          This UI aims at imitating the style of the control plane of this DLC, and I may publish it soon, if possible.
          </RightBody>
        </div>
      </div>
    )
  }
}
export default withRouter(TnoPage)